import { ReactElement, Fragment, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { Container, Grid, Button, Box, MenuItem } from '@mui/material'
import { Input, Loader } from 'components'
import { useAppContext } from 'hooks'
import { IRepository, IServerResponse } from 'types'
import {
	handleUpdateMode,
	handleCreateMode,
	handleNotification,
} from 'context/actions'
import { updateOne, createOne } from 'api'
import * as yup from 'yup'

const validationSchema = yup.object({
	name: yup
		.string()
		.required("Поле обов'язкове"),
	link: yup
		.string()
		.required("Поле обов'язкове"),
	year: yup.number().max(9999).required(),
	category: yup.string().required(),
})

type DataType = IRepository

interface IProps {
	data: DataType
	id?: string
}

const repoCategories = [
	{
		name: 'Історія',
		value: 'history',
	},
	{
		name: 'Соціологія',
		value: 'sociology',
	},
	{
		name: 'Політологія',
		value: 'politology',
	},
	{
		name: 'Публічне управління',
		value: 'public_administration',
	},
	{
		name: 'Публіцистика',
		value: 'journalism',
	},
]

const Form = ({ data, id }: IProps): ReactElement => {
	const [loading, setLoading] = useState(false)
	const { dispatch } = useAppContext()

	const formik: FormikProps<DataType> = useFormik<DataType>({
		initialValues: {
			...data,
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: async data => {
			const body = {
				name: data.name,
				year: data.year,
				link: data.link,
				category: data.category,
			}
			setLoading(true)
			try {
				let response: IServerResponse
				const fetchCategory = 'repository'

				if (id)
					response = await updateOne(fetchCategory, id, JSON.stringify(body))
				else response = await createOne(fetchCategory, JSON.stringify(body))

				if (response?.status === 200) {
					dispatch(handleNotification(true, 'success', 'Успішно'))
					dispatch(handleCreateMode(false))
					dispatch(handleUpdateMode(''))
				} else {
					dispatch(handleNotification(true, 'error', response?.message))
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				dispatch(handleNotification(true, 'error', error?.message))
			}
			setLoading(false)
		},
	})

	const handleBack = () => {
		dispatch(handleUpdateMode(''))
		dispatch(handleCreateMode(false))
	}

	return (
		<Fragment>
			{loading && <Loader />}
			<Container sx={{ m: 'auto', maxWidth: '1000px' }}>
				<Grid item mb='50px' sx={{ textAlign: 'end' }}>
					<Button onClick={handleBack}>Назад</Button>
				</Grid>
				<Box component='form' onSubmit={formik.handleSubmit}>
					<Grid container justifyContent='space-between' mb='30px'>
						<Input
							label='Назва'
							multiline
							name='name'
							rows={5}
							value={formik?.values?.name}
							onChange={formik.handleChange}
							error={formik?.touched?.name && Boolean(formik.errors.name)}
							helperText={formik?.touched?.name && formik?.errors?.name}
							required
						/>
						<Input
							label='Посилання'
							name='link'
							multiline
							rows={5}
							value={formik?.values?.link}
							onChange={formik.handleChange}
							error={formik?.touched?.link && Boolean(formik?.errors?.link)}
							helperText={formik?.touched?.link && formik?.errors?.link}
							required
						/>
						<Input
							label='Рік'
							name='year'
							type='number'
							value={formik?.values?.year}
							onChange={formik.handleChange}
							error={formik?.touched?.year && Boolean(formik?.errors?.year)}
							helperText={formik?.touched?.year && formik?.errors?.year}
							required
							sx={{ mt: '40px' }}
						/>
						<Input
							sx={{ mt: '40px' }}
							select
							label='Категорія'
							name='category'
							value={formik.values.category}
							onChange={formik.handleChange}
							defaultValue={repoCategories[0].value}
							required
						>
							{repoCategories?.map(category => (
								<MenuItem key={category.value} value={category.value}>
									{category.name}
								</MenuItem>
							))}
						</Input>
					</Grid>
					<Grid container mt='50px' justifyContent='flex-end'>
						<Button type='button' onClick={handleBack}>
							Скасувати
						</Button>
						<Button type='submit' sx={{ ml: '20px' }}>
							Зберегти
						</Button>
					</Grid>
				</Box>
			</Container>
		</Fragment>
	)
}

export default Form
